import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const ShelterPage: FC = () => (
  <Layout>
    <SEO title="Skjólfar" />
    <h2>Skjólfar</h2>
    <p>
      Verandi eyland út í miðju Atlantshafi er Ísland vindasamt land. Skjól af
      landi getur því verið afar mikilvægt fyrir vöxt trjágróðurs. Á
      vindasömustu svæðunum getur gott landskjól skilið milli feigs og ófeigs í
      tilviki skógræktar. Í þessari fyrstu útgáfu Skógarkolefnisreiknis er
      gerður munur á grósku skjólgóðra og skjóllítilla svæða í þeim landshlutum
      þar sem landskjólið er áhrifaríkast að okkar mati. Til að lýsa skjóli eru
      notaðar staðlaðar aðferðir við mat á landskjóli. Innan skógræktarinnar
      hefur þetta mat verið kallað „landskjól“ eða „landskjólgráður“ en á ensku
      er fyrirbærið kallað TOPEX sem er stytting á „topographic exposure“. Í
      stuttu máli er mældur halli lands í heilum gráðum miðað við sjónarrönd í
      átta höfuðáttir (Norður, Norðaustur, Austur, Suðaustur, Suður, Suðvestur,
      Vestur og Norðvestur). Gráður fyrir hverja átt eru síðan lagðar saman og
      summan myndar landskjól. Þegar metið er við sjónarrönd geta gráður hverrar
      áttar verið frá 0 og fræðilega upp í 720. Hægt er að velja aðra matslengd
      en sjónarrönd og er þá oftast valin föst matslengd. Í þeim tilvikum getur
      skjólgráða áttar verið mínustala, t.d. þegar mælt er niður úr brekku. Hér 
      á Íslandi hafa skógfræðingar mælt „skjólgráður“ og í verkefnunum Úttekt á
      skógræktarskilyrðum og Landsskógarúttekt hefur skjólgráða verið mæld bæði
      við sjónarrönd (kallað fjærskjól) og við 50 m fjarlægð frá mælistað (kallað 
      nærskjól). Nánari upplýsingar um mat á landskjóli er að finna í eftirfarandi
      yfirlitsgrein.
    </p>
    <p>
      <a href="https://rmets.onlinelibrary.wiley.com/doi/pdf/10.1017/S1350482700001729">
        Chapman L. (2000). Assessing topographic exposure. Meterological
        Applications, 7: 335-340
      </a>
    </p>
    <p>
      Þau svæði á Íslandi þar sem við viljum gera greinarmun á skjólgóðum og
      skjóllitlu landi eru; Austfirðir, V-Skaftafellssýsla (aðallega undir
      Eyjafjöllum), Suðurlandsundirlendi, Reykjanes, Hvalfjörður og Akranes,
      Borgarfjörður og Mýrar, Snæfellsnes og Hnappadalur og inndalir
      Þingeyjarsveitar. Áhrif skjólfars er mismunandi eftir trjátegundum og
      hæðarbilum lands. Með því að leggja saman fjærskjólgráðu og nærskjólgráðu
      x 0,5 fæst sæmilegt jafnaðargildi fyrir skjólfar. Staðir sem skiluðu góðum
      vexti fyrir stafafuru, sitkagreni og alaskaösp og töldust skjólgóðir á
      Vesturlandi, Suðurlandi og Austfjörðum í úttekt á skógræktarskilyrðum voru
      allir með slíkt skjólgildi frá 60 til 200 með miðgildi milli 60-79.
    </p>
  </Layout>
)

export default ShelterPage
